import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "./Login.css";
import logodark from "../../assets/img/logo-dark.png";
import { useNavigate } from "react-router-dom";
import { ErrorMessage } from "@hookform/error-message";
import Button from "../../components/ui/Button/Button";
import emailicon from "../../assets/svg/email-icon.svg";
import passwordicon from "../../assets/svg/password-icon.svg";
import { Base64 } from "js-base64";

export default function Login() {
  let navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [ls, setLs] = useState(false);
  const [err, setErr] = useState();
  const [hide, setHide] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [verPass, setverPass] = useState(false);
  const [errG, seterrG] = useState(null);

  useEffect(() => {
    var logged_in = JSON.parse(localStorage.getItem("isLoggedIn"));

    if (logged_in === null) {
      localStorage.setItem("isLoggedIn", ls);
    }

    if (hide === false) {
      setTimeout(() => {
        setHide(true);
        setErr(null);
      }, 2500);
    }
  }, [ls, hide]);

  const handleKeydown = (e) => {
    if (e.keyCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  const onSubmit = (values) => {
    setIsLoading(true);

    fetch(`${process.env.REACT_APP_URL}login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        user: {
          email: values.email,
          password: values.password,
        },
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (!json.error) {
          setLs(true);
          sessionStorage.setItem("1nf0", json.tkn);
          localStorage.setItem("username", Base64.encode(values.email));
          localStorage.setItem("r0l", Base64.encode(json.rol));
          navigate("/dashboard", { replace: true });
          localStorage.setItem("isLoggedIn", true);
        } else {
          setErr(json.error);
          setIsLoading(false);
          throw new Error("Bad Response from server");
        }
      })
      .catch((error) => {
        seterrG("Hubo un percance con los datos enviados.");
        setHide(false);
        setIsLoading(false);
        localStorage.setItem("isLoggedIn", ls);
      });
  };

  return (
    <div className="container-fluid vh-100">
      <div className="row r-login">
        <div className="col-lg-6 d-none d-lg-block bg-left">
          <div className="d-md-flex flex-column align-items-start h-100 p-5 text-center">
            <div className="logoarea mt-auto pt-5 pb-5">
              <p className="left-text">
                Creemos
                <br />
                que las buenas
                <br />
                conexiones inspiran
                <br />
                ideas increíbles
              </p>
              <p className="mb-0 l-txt" style={{ opacity: 1 }}>
                Bienvenidos al portal de
                <span className="fw-bold"> Operaciones.</span>
              </p>
            </div>
            <div className="d-flex flex-column">
              <p className="mb-0 l-txt">
                Corporación Matrix TV C.A Rif: J - 297655948 | Conexsoft -
                Proham
              </p>
              <p className="mb-0 l-txt">Todos los derechos reservados© 2022</p>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 login ">
          <div className="d-md-flex align-items-center  text-center justify-content-center cont-lg">
            <div className="login-dv mx-auto align-items-center text-center">
              <img
                src={logodark}
                className="logo-dark img-fluid"
                alt="logo airtek"
              />
              <div className="text-center">
                <div className="input-group mb-3">
                  <span
                    className={`input-group-text-rec ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    id="basic-addon1"
                  >
                    <img src={emailicon} alt="ícono correo" />
                  </span>
                  <input
                    className={`form-control-rec ps-0 ${
                      errors.email ? "is-invalid" : ""
                    }`}
                    type="email"
                    onKeyDown={handleKeydown}
                    placeholder="Correo electrónico"
                    {...register("email", {
                      required: "El campo correo electrónico es obligatorio *",
                      pattern: {
                        value: /^\S+@\S+$/i,
                        message:
                          "Ingrese una dirección de correo electrónico válida(por ejemplo, airtek@airtek.com.ve).",
                      },
                    })}
                  />
                </div>
                <div className="input-group">
                  <span
                    className={`input-group-text-rec ${
                      errors.password ? "is-invalid" : ""
                    }`}
                    id="basic-addon2"
                  >
                    <img src={passwordicon} alt="ícono contraseña" />
                  </span>
                  <input
                    className={`form-control-rec border-end-0 ps-0 ${
                      errors.password ? "is-invalid" : ""
                    }`}
                    type={verPass ? "text" : "password"}
                    onKeyDown={handleKeydown}
                    placeholder="Contraseña"
                    {...register("password", {
                      required: "El campo contraseña es obligatorio *",
                    })}
                  />
                  <span
                    className={`input-group-text-lg  ${
                      errors.password ? "is-invalid" : ""
                    }`}
                    id="basic-addon3"
                  >
                    <i
                      className={
                        verPass
                          ? "fa-solid fa-eye eye-pwd"
                          : "fa-solid fa-eye-slash eye-pwd"
                      }
                      onClick={() => setverPass(!verPass)}
                    ></i>
                  </span>
                </div>
                <ErrorMessage
                  className="lg-error"
                  errors={errors}
                  name="password"
                  as="p"
                />
                <ErrorMessage
                  errors={errors}
                  name="email"
                  className="lg-error"
                  as="p"
                >
                  {({ messages }) => {
                    return (
                      messages &&
                      Object.entries(messages).map(([type, message]) => (
                        <p className="lg-error" key={type}>
                          {message}
                        </p>
                      ))
                    );
                  }}
                </ErrorMessage>
                {(err !== undefined || errG !== null) && hide === false && (
                  <div className="lg-is-error">
                    <p className="lg-error"> {err ? err : errG} </p>
                  </div>
                )}
                <Button
                  text={"Iniciar Sesión"}
                  myClass={"btn rounded-pill w-100 btn-login"}
                  funct={handleSubmit(onSubmit)}
                  loading={isLoading}
                  loadingMsg={"Iniciando Sesión"}
                />
              </div>
            </div>
          </div>
          <div className="footer-a">
            <div className="text-center align-items-center">
              <a href="https://airtek.com.ve/" className="mb-0 website">
                www.airtek.com.ve
              </a>
              <p className="mb-2">
                Síguenos en nuestras
                <span className="sm"> Redes Sociales </span>
              </p>
              <div className="mx-auto">
                <a href="https://www.instagram.com/airtekinternet/">
                  <i className="icon-sm fab fa-instagram me-3"> </i>
                </a>
                <a href="https://www.facebook.com/airtekesinternet/">
                  <i className="icon-sm fab fa-facebook me-3"> </i>
                </a>
                <a href="https://www.youtube.com/c/AirtekesInternet">
                  <i className="icon-sm fab fa-youtube me-3"> </i>
                </a>
                <a href="https://twitter.com/airtekinternet">
                  <i className="icon-sm fab fa-twitter me-3"> </i>
                </a>
                <a href="https://t.me/airtekinternet">
                  <i className="icon-sm fab fa-telegram-plane"> </i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row r-footer">
        <div className="footer-mobile">
          <p className="footerm-txt">
            Corporación Matrix TV C.A Rif: J - 297655948 | Conexsoft - Proham
          </p>
          <p className="footerm-txt"> Todos los derechos reservados© 2022 </p>
        </div>
      </div>
    </div>
  );
}
