import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const Loader = ({ h }) => {
  return (
    <>
      <Skeleton height={h ? h : 300} />
    </>
  );
};

export default Loader;
