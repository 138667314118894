import React from "react";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <div className="main-nb">
      <nav className="navbar navbar-m mb-3 mt-3">
        <div className="container-fluid p-0">
          <div className="ms-auto d-flex align-items-center">
            <div className="d-flex align-items-center">
              <div>
                <button
                  className="circle circle-lg position-relative me-2"
                  onClick={() => {
                    navigate("/");
                    localStorage.clear();
                    sessionStorage.clear();
                  }}
                >
                  <i className={`nb-icon fas fa-sign-out-alt`}></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
