import React, { useState, useEffect } from "react";
import Navbar from "../../components/ui/Navbar/Navbar";
import Sidebar from "../../components/ui/Sidebar/Sidebar";
import "./Main.css";
import "./Calendar.css";
import "./DateSelector.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import ListGroup from "../../components/ui/ListGroup/ListGroup";
import MainMDT from "../../views/Main/MainMDT/MainMDT";
import Error from "../../components/ui/Error/Error";
import Tecnicos from "../../views/Main/Tecnicos/Tecnicos";
import { ExportToExcel } from "../../utils/export/ExportExcel/ExportExcel";
import Select1 from "../../components/ui/Select/Select";
import { DateFormatter } from "../../components/extras/DateFormatter";
import Loader from "../../components/ui/Loaders/Loader";
import { ExportMSheets } from "../../utils/export/ExportExcel/ExcelMSheets";
import BarChart1 from "../../components/ui/BarChart/BarChart";
import PieChartt from "../../components/ui/PieChart/PieChartt";

const Main = () => {
  function getFirstDayOfMonth(year, month) {
    return new Date(year, month, 1);
  }

  const date = new Date();
  const firstDayCurrentMonth = getFirstDayOfMonth(
    date.getFullYear(),
    date.getMonth()
  );

  const [searchTerm, setSearchTerm] = useState(null);
  const [asig, setasig] = useState(null);
  const [tgraficas, settgraficas] = useState(null);
  const [zonasl, setzonasl] = useState(null);
  const [motivosg, setmotivosg] = useState(null);
  const [value, onChange] = useState([firstDayCurrentMonth, new Date()]);
  const [export_d, setexport] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const [s_sucursal, sets_sucursal] = useState(0);
  const [isLoading, setisLoading] = useState({
    ordenes: false,
    cumplidas: false,
  });
  const [tk_h] = useState(sessionStorage.getItem("1nf0"));
  const [aliados, setaliados] = useState(0);
  const [value2, onChange2] = useState([new Date(), new Date()]);
  const [totalGen, settotalGen] = useState(null);
  const [tecnicos, settecnicos] = useState(null);
  const [grafAsig, setgrafAsig] = useState(null);
  const [grafCump, setgrafCump] = useState(null);

  useEffect(() => {
    const getDataP = () => {
      // get sucursales
      fetch(`${process.env.REACT_APP_URL}sucursales`, {
        headers: {
          "Content-Type": "application/json",
          Authentication: `${tk_h}`,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          setSucursales(json);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getDataP();
  }, [tk_h]);

  // ici
  useEffect(() => {
    const fechasF = DateFormatter(value);
    const fechasEmi = DateFormatter(value2);

    const getDataG = () => {
      setisLoading((prev) => ({
        ...prev,
        ordenes: true,
        cumplidas: true,
      }));

      // get grafica ultima milla -- filter x Sucursal / Fecha
      fetch(
        `${process.env.REACT_APP_URL}ordenes/${fechasF[0]}/${fechasF[1]}/${s_sucursal}/${aliados}/${fechasEmi[0]}/${fechasEmi[1]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authentication: `${tk_h}`,
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          setisLoading((prev) => ({
            ...prev,
            ordenes: false,
          }));

          // ultima milla
          setasig(json.grafica);
          setexport(json.data);
          setSearchTerm(json.qty);
          setmotivosg(json.motivos);
          setzonasl(json.zona);

          var tiempos_asig = json.tiempos_asig;
          settgraficas(tiempos_asig);

          var totalTiemposA = 0;
          tiempos_asig.grafica.forEach((element) => {
            totalTiemposA = totalTiemposA + element.value;
          });

          settotalGen(totalTiemposA);
        })
        .catch((err) => {
          console.log(err);
          setisLoading((prev) => ({
            ...prev,
            ordenes: false,
          }));

          // ultima milla
          setasig(null);
          setexport(null);
          setSearchTerm(null);
          setmotivosg(null);
          setzonasl(null);

          settgraficas(null);

          settotalGen(null);
        });

      // get tiempos de gestion -- filter x Sucursal / Fecha
      fetch(
        `${process.env.REACT_APP_URL}ordenes-cumplidas/${fechasF[0]}/${fechasF[1]}/${s_sucursal}/${aliados}/${fechasEmi[0]}/${fechasEmi[1]}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authentication: `${tk_h}`,
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          setisLoading((prev) => ({
            ...prev,
            cumplidas: false,
          }));

          settecnicos(json.tecnicos);
          setgrafAsig(json.asigc);
          setgrafCump(json.cumplimiento);
        })
        .catch((err) => {
          setisLoading((prev) => ({
            ...prev,
            cumplidas: false,
          }));

          settecnicos(null);
          setgrafAsig(null);
          setgrafCump(null);
          console.log(err);
        });
    };

    getDataG();
  }, [value, s_sucursal, tk_h, aliados, value2]);

  const [isLoggedIn] = useState(JSON.parse(localStorage.getItem("isLoggedIn")));

  return (
    <>
      {isLoggedIn === true ? (
        <div className="wrapper w-100 d-flex align-items-stretch">
          <Sidebar />
          <div id="content" className="main-cnt">
            <div className="container main-ct w-100 p-4">
              <Navbar />

              <div className="row">
                <div className="d-flex align-items-center wrapper-f">
                  <p className="traf">Resumen de Datos de Gestión</p>

                  <div className="d-flex justify-items-center ms-auto h-100 wrapper-f">
                    <div className="d-flex h-100 f-date">
                      <DateRangePicker
                        required={true}
                        onChange={onChange}
                        clearIcon={null}
                        value={value}
                        maxDate={new Date()}
                        format={"dd-MM-y"}
                        rangeDivider={"al"}
                      />

                      <div className="btn-group">
                        <button
                          style={{
                            borderTopLeftRadius: "0px",
                            borderBottomLeftRadius: "0px",
                            backgroundColor: "white",
                            color: "#111",
                            height: "100%",
                            border: "none",
                          }}
                          className="btn btn-secondary dropdown-toggle"
                          type="button"
                          id="dropdownMenuClickableInside"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          aria-expanded="false"
                        ></button>
                        <ul
                          className="dropdown-menu dropdown-menu-end w-100"
                          aria-labelledby="dropdownMenuClickableInside"
                          style={{
                            minWidth: "30rem",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                          }}
                        >
                          <li className="dropdown-item drp-fec w-100 p-0">
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                alignItems: "center",
                                padding: "0px !important",
                                justifyContent: "space-evenly",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "13px",
                                  textAlign1: "center",
                                  fontWeight: "550",
                                  paddingRight: "10px",
                                }}
                              >
                                Fecha de emisión:
                              </span>

                              <DateRangePicker
                                required={true}
                                onChange={onChange2}
                                //clearIcon={null}
                                value={value2}
                                maxDate={new Date()}
                                format={"dd-MM-y"}
                                rangeDivider={"al"}
                              />
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div className="ms-2 select-main">
                      <Select1
                        onSearch={(value) => {
                          sets_sucursal(value);
                        }}
                        data={sucursales}
                        placeholder={"Seleccionar Sucursal"}
                        zeroValue={true}
                      />
                    </div>

                    <div className="ms-3 form-check form-switch mb-0 switch-ac">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        checked={aliados === 0 ? false : true}
                        id="flexSwitchAC"
                        onChange={() => {
                          setaliados(aliados === 0 ? "x" : 0);
                        }}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="flexSwitchAC"
                        style={{
                          fontSize: "14px",
                        }}
                      >
                        Filtrar AC
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-md-12 col-sm-12 col-listg mt-3">
                  <div className="d-flex flex-column h-100 justify-content-around card p-4">
                    <p className="traf">Total de Ordenes</p>

                    {isLoading && isLoading.ordenes ? (
                      <>
                        <Loader />
                      </>
                    ) : (
                      <>
                        {searchTerm !== null ? (
                          <>
                            <ListGroup
                              data={searchTerm}
                              title={"Total de Ordenes"}
                            />
                          </>
                        ) : (
                          <div className="d-flex my-auto mx-auto mt-3">
                            <div className="h-100 my-auto">
                              <Error
                                title={"No se han encontrado registros."}
                                icon={"fa-solid fa-circle-xmark icon-error"}
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="col-lg-9 col-md-12 col-sm-12 mt-3">
                  <div
                    className="card"
                    style={{
                      backgroundColor: "#fff",
                      padding: "5px",
                    }}
                  >
                    {isLoading && isLoading.ordenes ? (
                      <>
                        <p className="traf mb-0">
                          Indicadores de Averías Última Milla
                        </p>
                        <Loader />
                      </>
                    ) : (
                      <>
                        {asig !== null ? (
                          <>
                            <div className="d-flex justify-content-between mb-1 align-items-baseline mb-3">
                              <p className="traf mb-0">
                                Indicadores de Averías Última Milla
                              </p>

                              <div className="d-flex align-items-center">
                                <div className="me-2">
                                  <ExportToExcel
                                    apiData={export_d}
                                    fileName={"Total de Ordenes"}
                                  />
                                </div>
                              </div>
                            </div>

                            <div>
                              <BarChart1 data={asig} />
                            </div>
                          </>
                        ) : (
                          <>
                            <p className="traf mb-0">
                              Indicadores de Averías Última Milla
                            </p>
                            <div className="d-flex my-auto mx-auto mt-5">
                              <div className="h-100 my-auto">
                                <Error
                                  title={"No se han encontrado registros."}
                                  icon={"fa-solid fa-circle-xmark icon-error"}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-lg-6 col-md-12 col-sm-12 col-chart">
                  <div className="card">
                    {isLoading && isLoading.ordenes ? (
                      <>
                        <p className="traf">Tiempos de Asignación</p>
                        <Loader />
                      </>
                    ) : (
                      <>
                        {tgraficas !== null ? (
                          <>
                            <div className="d-flex justify-content-between w-100">
                              <p className="traf">Tiempos de Asignación</p>
                              <ExportMSheets
                                apiData={tgraficas.export}
                                fileName={"Tiempos de Asignación"}
                                field={"name"}
                              />
                            </div>

                            <PieChartt
                              data={tgraficas.grafica}
                              legend
                              total={totalGen}
                              info={tgraficas.response}
                            />
                          </>
                        ) : (
                          <>
                            <p className="traf">Tiempos de Asignación</p>
                            <div className="d-flex my-auto mx-auto mt-3">
                              <div className="h-100 my-auto">
                                <Error
                                  title={"No se han encontrado registros."}
                                  icon={"fa-solid fa-circle-xmark icon-error"}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 col-chart">
                  <div className="card">
                    {isLoading && isLoading.cumplidas ? (
                      <>
                        <p className="traf">
                          Tiempos de Cumplimiento desde la Asignación
                        </p>
                        <Loader />
                      </>
                    ) : (
                      <>
                        {grafAsig !== null &&
                        Object.values(grafAsig.response).every(
                          (o) => o.refs.length === 0
                        ) === false ? (
                          <>
                            <div className="d-flex justify-content-between w-100">
                              <p className="traf">
                                Tiempos de Cumplimiento desde la Asignación
                              </p>
                              <ExportMSheets
                                apiData={grafAsig.export}
                                fileName={
                                  "Tiempos de Cumplimiento desde la Asignación"
                                }
                                field={"name"}
                              />
                            </div>

                            <PieChartt
                              data={grafAsig.grafica}
                              legend
                              total={searchTerm && searchTerm.Cumplidas.total}
                              info={grafAsig.response}
                            />
                          </>
                        ) : (
                          <>
                            <p className="traf">
                              Tiempos de Cumplimiento desde la Asignación
                            </p>
                            <div className="d-flex my-auto mx-auto mt-3">
                              <div className="h-100 my-auto">
                                <Error
                                  title={"No se han encontrado registros."}
                                  icon={"fa-solid fa-circle-xmark icon-error"}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="row mt-5">
                <div className="col-lg-6 col-md-12 col-sm-12 col-chart">
                  <div className="card">
                    {isLoading && isLoading.cumplidas ? (
                      <>
                        <p className="traf">
                          Tiempos de Cumplimiento desde la Emisión
                        </p>
                        <Loader />
                      </>
                    ) : (
                      <>
                        {grafCump !== null &&
                        Object.values(grafCump.response).every(
                          (o) => o.refs.length === 0
                        ) === false ? (
                          <>
                            <div className="d-flex justify-content-between w-100">
                              <p className="traf">
                                Tiempos de Cumplimiento desde la Emisión
                              </p>

                              <ExportMSheets
                                apiData={grafCump.export}
                                fileName={"Tiempos de Cumplimiento"}
                                field={"name"}
                              />
                            </div>

                            <PieChartt
                              data={grafCump.grafica}
                              legend
                              total={searchTerm && searchTerm.Cumplidas.total}
                              info={grafCump.response}
                            />
                          </>
                        ) : (
                          <>
                            <p className="traf">
                              Tiempos de Cumplimiento desde la Emisión
                            </p>
                            <div className="d-flex my-auto mx-auto mt-3">
                              <div className="h-100 my-auto">
                                <Error
                                  title={"No se han encontrado registros."}
                                  icon={"fa-solid fa-circle-xmark icon-error"}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 col-md-12 col-sm-12 col-chart">
                  <div className="card">
                    {isLoading && isLoading.ordenes ? (
                      <>
                        <p className="traf">Zonas: Nodos</p>
                        <Loader />
                      </>
                    ) : (
                      <>
                        {zonasl !== null ? (
                          <>
                            <div className="d-flex justify-content-between w-100">
                              <p className="traf">Zonas: Nodos</p>

                              <ExportMSheets
                                apiData={zonasl.exp}
                                fileName={"Zonas - Nodos"}
                                field={"name"}
                                nNumber
                              />
                            </div>

                            <PieChartt
                              chart_title={"Zona"}
                              data={zonasl.grafica}
                              legend
                              total={export_d.length}
                              info={zonasl.result}
                            />
                          </>
                        ) : (
                          <>
                            <p className="traf">Zonas: Nodos</p>
                            <div className="d-flex my-auto mx-auto mt-3">
                              <div className="h-100 my-auto">
                                <Error
                                  title={"No se han encontrado registros."}
                                  icon={"fa-solid fa-circle-xmark icon-error"}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="row mt-5 mb-5">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card">
                    {isLoading && isLoading.ordenes ? (
                      <>
                        <p className="traf">Motivos</p>
                        <Loader />
                      </>
                    ) : (
                      <>
                        {motivosg !== null ? (
                          <>
                            <div className="d-flex justify-content-between w-100">
                              <p className="traf">Motivos</p>

                              <ExportMSheets
                                apiData={motivosg.exp}
                                fileName={"Motivos"}
                                field={"name"}
                                nNumber
                              />
                            </div>

                            <PieChartt
                              data={motivosg.grafica}
                              legend
                              total={export_d.length}
                              info={motivosg.result}
                            />
                          </>
                        ) : (
                          <>
                            <p className="traf">Motivos</p>
                            <div className="d-flex my-auto mx-auto mt-3">
                              <div className="h-100 my-auto">
                                <Error
                                  title={"No se han encontrado registros."}
                                  icon={"fa-solid fa-circle-xmark icon-error"}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="row mt-5 mb-5">
                <div className="col-12">
                  <div className="card p-4">
                    {isLoading && isLoading.cumplidas ? (
                      <>
                        <p className="traf mb-0">Técnicos</p>
                        <Loader />
                      </>
                    ) : (
                      <>
                        <Tecnicos date={value} tec={tecnicos} />
                      </>
                    )}
                  </div>
                </div>
              </div>

              <hr
                style={{
                  border: "2px solid #111",
                }}
              />

              <MainMDT sucursales={sucursales} />
            </div>
          </div>
        </div>
      ) : (
        <Error
          title={"Acceso denegado"}
          text={"La página a la que intenta acceder tiene acceso restringido."}
          page
          page_icon
          icon={"fa-solid fa-lock lock-icon"}
        />
      )}
    </>
  );
};

export default Main;
