import {
  PieChart,
  Pie,
  Legend,
  Cell,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import "./styles.css";
import React, { useState } from "react";
import Modal1 from "../Modal/Modal";

const COLORS = [
  "#4472C4",
  "#ED7D31",
  "#A5A5A5",
  "#FFC000",
  "#005F6B",
  "#5B9BD5",
  "#C5BC8E",
  "#696758",
  "#70AD47",
  "#29c195",
  "#008C9E",
  "#00B4CC",
  "#00DFFC",
  "#00bdc0",
  "#45484B",
  "#36393B",
  "#96ba2e",
  "#0066FF",
];

const Bullet = ({ backgroundColor, size }) => {
  return (
    <div
      className="CirecleBullet"
      style={{
        backgroundColor,
        width: size,
        height: size,
      }}
    ></div>
  );
};

const CustomTooltip = (props, active) => {
  const { payload } = props;

  var percentage = ((100 * payload?.[0]?.payload?.value) / props.tot).toFixed(
    2
  );

  if (active) {
    return (
      <div
        style={{
          backgroundColor: "#111",
          padding: "5px",
          display: "flex",
          alignItems: "center",
          outline: "none",
          boxShadow: "none",
          borderRadius: 7,
          border: "none",
        }}
      >
        <div
          className="d-flex align-items-center"
          style={{
            fontSize: "12px",
            color: "#FFF",
            margin: "auto",
            backgroundColor: "#111",
          }}
        >
          <div>
            <Bullet backgroundColor={payload?.[0]?.payload?.fill} size="15px" />
          </div>
          <p className="ms-2 mb-0">
            {payload?.[0]?.payload?.name === ""
              ? "NO DEFINIDO"
              : payload?.[0]?.payload?.name}
          </p>
          : <p className="ms-2 mb-0">{payload?.[0]?.payload?.value}</p>
          <p className="ms-2 mb-0 fw-bold">({percentage}%)</p>
        </div>
      </div>
    );
  }

  return null;
};

const PieChartt = ({ data, legend, total, info, motivos }) => {
  const [modal2, setModal2] = useState(false);
  const [selected, setselected] = useState([]);
  const [info1, setinfo] = useState([]);

  const Open2 = () => {
    setModal2(!modal2);
  };

  const CustomizedLegend = (props) => {
    const { payload } = props;

    return (
      <ul className="LegendList">
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            onClick={(e) => {
              var openM = info[data[index].name].refs;

              if (openM.length !== 0) {
                Open2();

                setinfo(info[data[index].name].refs);

                setselected({
                  label: data[index].name,
                  dataf: info[data[index].name].refs,
                });
              }
            }}
          >
            <div className="BulletLabel">
              <div>
                <Bullet backgroundColor={entry.payload.fill} size="15px" />
              </div>

              <div className="BulletLabelText">
                {entry.value === "" ? "NO DEFINIDA" : entry.value}:{" "}
                {entry.payload.value}
                <b className="ms-1">
                  ({((100 * entry.payload.value) / props.tot).toFixed(2)} %)
                </b>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        minHeight: "350px",
        maxHeight: "800px",
      }}
    >
      <ResponsiveContainer width="100%" height="76%">
        <PieChart>
          <Pie
            data={data}
            cx={"50%"}
            cy={"70%"}
            outerRadius={100}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                stroke=""
                fill={COLORS[index % COLORS.length]}
                onClick={(e) => {
                  Open2();
                  setinfo(info[data[index].name].refs);

                  setselected({
                    label: data[index].name,
                    dataf: info[data[index].name].refs,
                  });
                }}
              />
            ))}
          </Pie>
          {legend && (
            <Legend
              wrapperStyle={{
                position: "relative",
                height: "100%",
                maxHeight: "100px",
                bottom: "16px",
                overflow: "auto",
              }}
              content={<CustomizedLegend tot={total} />}
            />
          )}
          <Tooltip
            content={
              <CustomTooltip
                tot={total}
                cursor={{ fill: "transparent" }}
                wrapperStyle={{ outline: "none" }}
              />
            }
          />
        </PieChart>
      </ResponsiveContainer>

      <Modal1
        open={modal2}
        close={Open2}
        info={info1}
        data={selected}
        motivos={motivos}
        btn_close
      />
    </div>
  );
};
export default PieChartt;
