import "./Sidebar.css";
import React from "react";
import { slide as Menu } from "react-burger-menu";
import icon from "../../../assets/svg/Sidebar-icon.svg";
import sb_closed from "../../../assets/svg/Vector.svg";
import logo from "../../../assets/svg/Logo.svg";
import Home from "../../../assets/svg/Home-icon.svg";
import { Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <div>
      <Menu
        customBurgerIcon={<img src={sb_closed} alt="ícono sidebar" />}
        customCrossIcon={<img src={icon} alt="ícono cerrar" />}
      >
        <div>
          <Link id="logo" className="sb-item menu-item" to="/dashboard">
            <img src={logo} alt="logo airtek" />
          </Link>

          <Link id="home" className="sb-item menu-item" to="/dashboard">
            <img src={Home} alt="ícono inicio" className="me-3" />
            Inicio
          </Link>

          <div
            className="accordion accordion-flush mt-4 dropdown-sbar"
            id="accordionFlushExample2"
          >
            <div className="accordion-item">
              <h2 className="accordion-header" id="flush-headingTwo">
                <button
                  className="accordion-button dropdown-sb collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseTwo"
                  aria-expanded="false"
                  aria-controls="flush-collapseTwo"
                >
                  <i className="fa-solid fa-gear me-3"></i>
                  Configuración
                </button>
              </h2>
              <div
                id="flush-collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample2"
              >
                <div className="accordion-body">
                  <Link
                    id="cambiar-pwd"
                    className="sb-item menu-item"
                    to="/cambiar-pwd"
                  >
                    Cambiar Contraseña
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <footer className="sb-footer">
            <div className="d-flex">
              <p className="mb-0 sb-version">
                v.{process.env.REACT_APP_VERSION}
              </p>
            </div>
          </footer>
        </div>
      </Menu>
    </div>
  );
};

export default Sidebar;
