export function DateFormatter(value) {
  var date_if = [];

  if (value !== null) {
    value.forEach((element) => {
      var _date = element.getDate();
      _date = `${_date}`.padStart(2, "0");

      var _month = element.getMonth() + 1;
      _month = `${_month}`.padStart(2, "0");

      var _year = element.getFullYear();

      var fechaf = _date + "-" + _month + "-" + _year;

      date_if.push(fechaf);
    });
  }
  return date_if;
}
