import React, { useState } from "react";
import Modal1 from "../Modal/Modal";
import "./ListGroup.css";

const ListGroup = ({ data }) => {
  const [modal2, setModal2] = useState(false);
  const [selected, setselected] = useState([]);
  const [info, setinfo] = useState([]);

  const Open2 = () => {
    setModal2(!modal2);
  };

  // const total = Object.values(data).reduce(
  //   (total, obj) => obj.total + total,
  //   0
  // );

  return (
    <>
      {Object.keys(data).map((key, index) => {
        return (
          <div
            key={index}
            onClick={() => {
              if (data[key] && data[key].total !== 0) {
                Open2();

                setinfo(data[key].info);

                setselected({
                  label: `${key}`,
                  dataf: data[key].info,
                });
              }
            }}
            style={{
              cursor: "pointer",
            }}
          >
            <div className="d-flex justify-content-between exp">
              <p className="mb-0 txt-lg">
                {key}
                <span className="ms-1 fw-bold">
                  ({key.charAt(0).toUpperCase()})
                </span>
              </p>
              <span className="badge">
                {data[key].total >= 0 ? (
                  data[key].total
                ) : (
                  <>
                    <div
                      className="spinner-border text-light spinner-border-sm"
                      role="status"
                    >
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </>
                )}
              </span>
            </div>
          </div>
        );
      })}

      {/* <div className="d-flex justify-content-between exp">
        <p className="mb-0 txt-lg">Total</p>
        <span className="badge">
          {!isNaN(total) ? (
            total
          ) : (
            <>
              <div
                className="spinner-border text-light spinner-border-sm"
                role="status"
              >
                <span className="visually-hidden">Loading...</span>
              </div>
            </>
          )}
        </span>
      </div> */}

      <Modal1
        open={modal2}
        close={Open2}
        info={info}
        btn_close
        data={selected}
      />
    </>
  );
};

export default ListGroup;
