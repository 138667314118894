import React, { useState } from "react";
import Select from "react-select";
import customStyles from "../../extras/customStylesSelect";

const Select1 = ({ onSearch, data, placeholder, zeroValue }) => {
  const [search, setSearch] = useState("");

  const onInputChange = (e) => {
    if (e !== null) {
      setSearch(e.value);
      onSearch(e.value);
    } else {
      if (zeroValue === true) {
        setSearch("0");
        onSearch("0");
      } else {
        setSearch("");
        onSearch("");
      }
    }
  };

  return (
    <div className="h-100">
      <Select
        onChange={onInputChange}
        value={search.value}
        isSearchable={true}
        placeholder={placeholder}
        options={data}
        noOptionsMessage={() => "No se han encontrado resultados"}
        styles={customStyles}
        isClearable
      />
    </div>
  );
};

export default Select1;
