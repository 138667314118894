import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import "./ExportExcel.css";

export const ExportMSheets = ({
  apiData,
  fileName,
  field,
  nNumber,
  allData,
}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName, field, nNumber, allData) => {
    var sheetNames = [];

    allData && sheetNames.push("total");

    apiData.forEach((element, i) => {
      var name_T = (nNumber ? i + 1 + "-" : "") + element[field].slice(0, 25);
      sheetNames.push(name_T.replace(/ /g, "").replace(/\//g, ''));
    });

    var newObj = Object.assign(
      {},
      ...apiData.map((item, j) => ({
        [(nNumber ? j + 1 + "-" : "") +
        item[field].slice(0, 25).replace(/ /g, "").replace(/\//g, '')]: XLSX.utils.json_to_sheet(
          item.refs
        ),
      }))
    );

    allData && (newObj["total"] = XLSX.utils.json_to_sheet(allData));

    const wb = { Sheets: newObj, SheetNames: sheetNames };

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <i
      className="fa-solid fa-download icon-download"
      onClick={(e) => exportToCSV(apiData, fileName, field, nNumber, allData)}
    ></i>
  );
};
