import React, { useState, useEffect, useMemo } from "react";
import "./Modal.css";
import ReactModal from "react-modal";
import { Link } from "react-router-dom";
import TableHeader from "../../ui/TableHeader/TableHeader";
import Search from "../SearchBar/SearchBar";

const Modal1 = ({
  open,
  close,
  data,
  title,
  txt,
  icon,
  title_class,
  btn_close,
  link_m,
  info,
  mdt,
}) => {
  const [openM, setOpen] = useState();
  const [search, setSearch] = useState("");
  const headers =
    mdt && mdt.mdt === true
      ? mdt.status === "C"
        ? [
            {
              name: "MDT",
              field: "Mdt",
            },
            {
              name: "OLT",
              field: "Olt",
            },
            {
              name: "Nodo",
              field: "Nodo",
            },
            {
              name: "Zona",
              field: "Zona",
            },
            {
              name: "Clientes Afectados",
              field: "Mdts",
            },
            {
              name: "Promedio de Atención",
              field: "PromAtencion",
            },
          ]
        : [
            {
              name: "MDT",
              field: "Mdt",
            },
            {
              name: "OLT",
              field: "Olt",
            },
            {
              name: "Nodo",
              field: "Nodo",
            },
            {
              name: "Zona",
              field: "Zona",
            },
            {
              name: "Clientes Afectados",
              field: "Mdts",
            },
          ]
      : [
          {
            name: "Nombres",
            field: "Nombres",
          },
          {
            name: "Motivo Cumplimiento",
            field: "MotivoCumplimiento",
          },
          {
            name: "Zona",
            field: "Zona",
          },
          {
            name: "Estatus",
            field: "Estatus",
          },
          {
            name: "Tecnico Cumplimiento",
            field: "TecnicoCumplimiento",
          },
          {
            name: "MDT",
            field: "Mdt",
          },
          {
            name: "Fecha Emisión",
            field: "FechaEmision",
          },
          {
            name: "Fecha Asignación",
            field: "FechaAsignacion",
          },
          {
            name: "Fecha Cumplimiento",
            field: "FechaCumplimiento",
          },
        ];

  useEffect(() => {
    setOpen(open);
  }, [open]);

  const commentsData = useMemo(() => {
    let computedComments = info;

    if (info && info.length > 0) {
      if (search !== "" && search !== null) {
        computedComments = computedComments.filter((o) =>
          Object.keys(o).some((k) =>
            String(o[k]).toLowerCase().includes(search.toLowerCase())
          )
        );
      }
    }

    return computedComments;
  }, [info, search]);

  return (
    <ReactModal
      appElement={document.querySelector(".App")}
      isOpen={openM}
      className="modal modal-dialog-centered w-100"
      tabIndex="-1"
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => {
        close();
        setSearch("");
      }}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, .5)",
        },
      }}
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content modal1">
          <div className="modal-header mhdr">
            {btn_close && (
              <button
                type="button"
                className="btn-close close-modal mt-1 me-1"
                onClick={() => {
                  close();
                  setSearch("");
                }}
              ></button>
            )}
          </div>

          <div className="modal-body mbdy h-100">
            <div className="d-flex h-100">
              <div className="m-auto text-center w-100">
                <i className={icon}></i>
                <p className={title_class}>{title}</p>

                {txt && <p>{txt}</p>}

                {link_m && (
                  <Link to="/" className="btn btn-drk">
                    Aceptar
                  </Link>
                )}

                {info && (
                  <>
                    <p className="m-title">{data.label}</p>
                    <Search
                      onSearch={(value) => {
                        setSearch(value);
                      }}
                      placeholder={"Buscar..."}
                    />

                    <div>
                      <div className="table-wrap">
                        <table className="table table-b table-bordered">
                          <TableHeader headers={headers} />

                          <tbody className="scroll-tbody-y table-body">
                            {commentsData &&
                              commentsData.length > 0 &&
                              commentsData.map((comment, index) => (
                                <React.Fragment key={index}>
                                  {mdt && mdt.mdt === true ? (
                                    <tr>
                                      <td>{comment.Mdt}</td>
                                      <td>{comment.Olt}</td>
                                      <td>{comment.Nodo}</td>
                                      <td>{comment.Zona}</td>
                                      <td>{comment.Mdts}</td>
                                      {mdt && mdt.status === "C" && (
                                        <td>{comment.PromAtencion} horas</td>
                                      )}
                                    </tr>
                                  ) : (
                                    <tr>
                                      <td>{comment.Nombres}</td>
                                      <td>{comment.MotivoCumplimiento}</td>
                                      <td>{comment.Zona}</td>
                                      <td>{comment.Estatus}</td>
                                      <td>{comment.TecnicoCumplimiento}</td>
                                      <td>{comment.Mdt}</td>
                                      <td>
                                        {comment.FechaEmision.replace("T", " ")}
                                      </td>
                                      <td>
                                        {comment.FechaAsignacion.replace(
                                          "T",
                                          " "
                                        )}
                                      </td>
                                      <td>
                                        {comment.FechaCumplimiento.replace(
                                          "T",
                                          " "
                                        )}
                                      </td>
                                    </tr>
                                  )}
                                </React.Fragment>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
};

export default Modal1;
