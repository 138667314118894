import React, { useState } from "react";
import "./SearchBar.css";

const Search = ({ onSearch, placeholder }) => {
  const [search, setSearch] = useState("");

  const onInputChange = (value) => {
    setSearch(value);
    onSearch(value);
  };

  return (
    <div className="input-group mb-3">
      <input
        type="text"
        className="form-control search"
        placeholder={placeholder}
        style={{ width: "205px" }}
        value={search}
        onChange={(e) => onInputChange(e.target.value)}
      />
      <span className="input-group-text sb-icon-wrp" id="basic-addon2">
        <i className="fa-solid fa-magnifying-glass"></i>
      </span>
    </div>
  );
};

export default Search;
