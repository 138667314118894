import React, { useState, useEffect } from "react";
import Error from "../../../components/ui/Error/Error";
import Modal1 from "../../../components/ui/Modal/Modal";
import Select1 from "../../../components/ui/Select/Select";
import { ExportToExcel } from "../../../utils/export/ExportExcel/ExportExcel";
import "./MainMDT.css";
import TableHeader from "../../../components/ui/TableHeader/TableHeader";
import Loader from "../../../components/ui/Loaders/Loader";

const MainMDT = ({ sucursales }) => {
  const [nodos, setNodos] = useState([]);
  const [mdt, setmdt] = useState();
  const [s_nodo, sets_nodo] = useState(0);
  const [table, settable] = useState([]);
  const [selected_row, setselected_row] = useState([]);
  const [mdt_arr, setmdt_arr] = useState([]);
  const [modal2, setModal2] = useState(false);
  const [export_F, setexport_F] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [s_sucursal, sets_sucursal] = useState(0);
  const [mdt_status, setmdt_status] = useState("P");
  const [mdtList, setMdtList] = useState([]);
  const [s_mdt, sets_mdt] = useState(0);

  useEffect(() => {
    const getData = () => {
      setisLoading(true);

      var pageView = sessionStorage.getItem("1nf0");

      // mdt list x Sucursal / Nodo / Estatus (cumplido / pendiente)
      fetch(
        `${process.env.REACT_APP_URL}mdt/${s_nodo}/${s_sucursal}/${mdt_status}/${s_mdt}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authentication: `${pageView}`,
          },
        }
      )
        .then((response) => response.json())
        .then((json) => {
          settable(json.grafica);
          setisLoading(false);
          setexport_F(json.data);
          const group_NDT = Object.entries(json.grafica).reduce(
            (acc, [key, value]) => {
              const newKey = Object.keys(json.grafica)
                .filter((key) => json.grafica[key].count === value.count)
                .join(`, `);
              return acc[newKey] ? acc : { ...acc, [newKey]: value.count };
            },
            {}
          );

          var labels_G = [];
          var data_G = [];
          var total_F = 0;

          for (let key in group_NDT) {
            data_G.push({
              name: key,
              value: group_NDT[key],
            });

            // suma total de mdts
            total_F = total_F + group_NDT[key];

            labels_G.push(key);
          }

          setmdt(data_G);
        })
        .catch((err) => {
          setisLoading(false);
          setmdt([]);
          console.log(err);
        });
    };

    getData();
  }, [s_nodo, s_sucursal, mdt_status, s_mdt]);

  const Open2 = () => {
    setModal2(!modal2);
  };

  useEffect(() => {
    const getDataS = () => {
      var pageView = sessionStorage.getItem("1nf0");

      // nodos list -- Select

      fetch(`${process.env.REACT_APP_URL}nodos`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authentication: `${pageView}`,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          // listado de status mdt
          var nodos_F = new Set();

          json.records.forEach((element) => {
            nodos_F.add(element.Nombrenodoolt);
          });

          var xds = [];

          Array.from(nodos_F).forEach((element) => {
            xds.push({ label: element, value: element });
          });

          setNodos(xds);
        })
        .catch((err) => {
          console.log(err);
        });

      fetch(`${process.env.REACT_APP_URL}mdt`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authentication: `${pageView}`,
        },
      })
        .then((response) => response.json())
        .then((json) => {
          setMdtList(json);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getDataS();
  }, []);

  const headers = [
    {
      name: "Motivos de orden",
      field: "MDT",
    },
    {
      name: "Total de clientes afectados",
      field: "clientes_A",
    },
  ];

  return (
    <>
      <div className="row mt-5 mb-5">
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="card">
            <div className="d-flex justify-content-between align-items-center w-100 mb-3">
              <div className="d-flex align-items-center w-100 justify-content-between av-controls">
                <p className="traf t-mdt mb-0">Averías: MDT</p>

                <div className="d-flex  align-items-center">
                  <div className="me-3">
                    <ExportToExcel
                      apiData={export_F}
                      fileName={"Averías por MDT"}
                    />
                  </div>

                  <div className="me-3">
                    <Select1
                      onSearch={(value) => {
                        sets_nodo(value);
                      }}
                      data={nodos}
                      placeholder={"Seleccionar Nodo"}
                      zeroValue={true}
                    />
                  </div>

                  <div className="me-3">
                    <Select1
                      onSearch={(value) => {
                        sets_sucursal(value);
                      }}
                      data={sucursales}
                      placeholder={"Seleccionar Sucursal"}
                      zeroValue={true}
                    />
                  </div>

                  <Select1
                    onSearch={(value) => {
                      sets_mdt(value);
                    }}
                    data={mdtList}
                    placeholder={"Seleccionar MDT"}
                    zeroValue={true}
                  />
                </div>
              </div>
            </div>

            <>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-p-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-p"
                    type="button"
                    role="tab"
                    onClick={() => {
                      setmdt_status("P");
                    }}
                    aria-controls="pills-p"
                    aria-selected="false"
                  >
                    Pendientes
                  </button>
                </li>

                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-c-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-c"
                    type="button"
                    role="tab"
                    onClick={() => {
                      setmdt_status("C");
                    }}
                    aria-controls="pills-c"
                    aria-selected="false"
                  >
                    Cumplidas
                  </button>
                </li>
              </ul>
              {!isLoading ? (
                <>
                  {mdt && mdt.length >= 1 ? (
                    <>
                      <div className="tab-content" id="myTabContent">
                        <div>
                          <table className="table table-mdt table-striped">
                            <TableHeader headers={headers} />
                            <tbody>
                              {table &&
                                Object.keys(table).length > 0 &&
                                Object.keys(table).map((obj, i) => (
                                  <tr key={i}>
                                    <td
                                      style={{
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                        fontWeight: 500,
                                      }}
                                      onClick={() => {
                                        Open2();
                                        setmdt_arr(table[obj].refs);
                                        setselected_row({
                                          label: obj,
                                          dataf: table[obj].refs,
                                        });
                                      }}
                                    >
                                      {!obj ? "NO DEFINIDO" : obj}
                                    </td>
                                    <td className="text-center">
                                      <span className="badge bg-primary rounded-pill">
                                        {table[obj].clientes_A}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="h-100">
                        <Error
                          title={"No se han encontrado registros."}
                          icon={"fa-solid fa-circle-xmark icon-error"}
                        />
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <div className="h-100">
                    <Loader h={"300px"} />
                  </div>
                </>
              )}
            </>
          </div>
        </div>
      </div>
      <Modal1
        open={modal2}
        close={Open2}
        mdt={{
          mdt: true,
          status: mdt_status,
        }}
        info={mdt_arr}
        data={selected_row}
        btn_close
      />
    </>
  );
};

export default MainMDT;
