import React from "react";
import "./TableHeader.css";

const TableHeader = ({ headers, id, type, handleClick, isChecked }) => {
  return (
    <thead className="text-center scroll-thead">
      <tr>
        {headers.map(({ name, hidden }) => (
          <th key={name} className={` ${hidden === true ? "d-none" : ""}`}>
            {name}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
