import React from "react";
import { BrowserRouter as HashRouter, Routes, Route } from "react-router-dom";
import Navigator from "../components/extras/Navigator/Navigator";
import Error from "../components/ui/Error/Error";
import ChangePwd from "../pages/ChangePwd/ChangePwd";
import Login from "../pages/Login/Login";
import Main from "../pages/Main/Main";

function App() {
  return (
    <div className="App">
      <Navigator />
      <HashRouter>
        <Routes>
          <Route exact path="/" element={<Login />} />
          <Route path="/dashboard" element={<Main />} />
          <Route path="/cambiar-pwd" element={<ChangePwd />} />

          <Route
            path="*"
            element={
              <Error
                title={"Oops Error"}
                text={"Esta página no está disponible."}
                page
              />
            }
          ></Route>
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
