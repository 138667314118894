import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Modal1 from "../Modal/Modal";
import "./LineChartt.css";

const LineChartt = ({ data }) => {
  const [modal2, setModal2] = useState(false);
  const [selected, setselected] = useState([]);
  const [label, setlabel] = useState([]);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          className="custom-tooltip"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.9)",
            color: "#FFF",
            fontSize: "12px",
            border: "1px solid #111",
            fontWeight: "600",
            padding: "10px",
            textAlign: "left",
          }}
        >
          <p className="label mb-0">{label}</p>
          <span className="fw-light">Cumplidas: {payload[0].value}</span>
        </div>
      );
    }

    return null;
  };

  const RenderLegend = (props) => {
    const { payload } = props;
    return (
      <div>
        {payload.map((entry, index) => (
          <React.Fragment key={`item-${index}`}>
            <i className="fa-solid fa-square" style={{ color: "#0066ff" }}></i>
            <span className="mx-2">Cumplidas</span>
          </React.Fragment>
        ))}
      </div>
    );
  };

  const Open2 = () => {
    setModal2(!modal2);
  };

  return (
    <div
      style={{
        boxSizing: "border-box",
        width: "100%",
        height: "900px",
        backgroundColor: "#fff",
      }}
    >
      <ResponsiveContainer>
        <BarChart
          data={data}
          layout={"vertical"}
          margin={{
            top: 10,
            right: 60,
            left: 200,
            bottom: 0,
          }}
          cursor="pointer"
        >
          <CartesianGrid strokeDasharray="4" />
          <XAxis allowDecimals={false} type="number" />

          <YAxis
            dataKey="name"
            type="category"
            tickSize={4}
            interval={0}
            axisLine={false}
            domain={[0, "dataMax"]}
            style={{ fontSize: "1px" }}
          />

          <Tooltip
            cursor={{ stroke: "#ccc", strokeWidth: 1 }}
            content={<CustomTooltip />}
          />
          <Legend
            content={<RenderLegend />}
            verticalAlign="bottom"
            wrapperStyle={{ left: "0px", textAlign: "center" }}
          />
          <Bar
            dataKey="refs.length"
            fill="#0066ff"
            barSize={6}
            barCategoryGap={16}
            onClick={(event) => {
              setselected(event.payload.refs);

              setlabel({
                label: event.payload.name,
                dataf: event.value,
              });

              Open2();
            }}
          >
            <LabelList dataKey="refs.length" position="right" />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <Modal1
        open={modal2}
        close={Open2}
        info={selected}
        data={label}
        btn_close
      />
    </div>
  );
};

export default LineChartt;
