import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const BarChart1 = ({ data }) => {
  // custom legend text
  const LegendTextStyles = (value) => {
    return <span style={{ color: "#111", fontSize: "12px" }}>{value}</span>;
  };

  const Bullet = ({ backgroundColor, size }) => {
    return (
      <div
        className="CirecleBullet"
        style={{
          backgroundColor,
          width: size,
          height: size,
          marginRight: "5px",
        }}
      ></div>
    );
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      return (
        <div
          style={{
            backgroundColor: "#111",
            padding: "5px",
            outline: "none",
            boxShadow: "none",
            borderRadius: 7,
            border: "none",
          }}
        >
          <p
            style={{
              color: "#FFF",
              textAlign: "center",
            }}
          >
            {label}
          </p>

          <>
            {payload.map(function (item, i) {
              return (
                <div
                  className="d-flex align-items-center mb-1"
                  style={{
                    fontSize: "12px",
                    color: "#FFF",
                    backgroundColor: "#111",
                  }}
                  key={i}
                >
                  <div>
                    <Bullet backgroundColor={item.fill} size="15px" />
                  </div>
                  {item.dataKey} <b> : {item.value}</b>
                </div>
              );
            })}
          </>
        </div>
      );
    }

    return null;
  };

  return (
    <>
      <ResponsiveContainer width="100%" aspect={3}>
        <BarChart
          data={data}
          barSize={17}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid />
          <XAxis dataKey="fecha" />
          <YAxis width={35} />
          <Tooltip
            content={<CustomTooltip />}
            cursor={{ fill: "transparent" }}
            wrapperStyle={{ outline: "none" }}
          />
          <Legend iconType={"circle"} formatter={LegendTextStyles} />
          <Bar dataKey="Cumplidas" fill="rgba(165, 165, 165, 1)" />
          <Bar dataKey="Asignadas" fill="rgba(237, 125, 49, 1)" />
          <Bar dataKey="Emitidas" fill="rgba(68, 114, 196, 1)" />
          <Bar dataKey="Resagadas" fill="#ffc000" />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
};

export default BarChart1;
