import React, { useState, useEffect } from "react";
import Error from "../../../components/ui/Error/Error";
import LineChartt from "../../../components/ui/LineChart/LineChartt";
import TableHeader from "../../../components/ui/TableHeader/TableHeader";
import { ExportMSheets } from "../../../utils/export/ExportExcel/ExcelMSheets";

const Tecnicos = ({ date, tec }) => {
  const [tecnicos, settecnicos] = useState(null);

  const [records, setRecords] = useState([]);

  useEffect(() => {
    if (tec !== null) {
      setRecords(tec.info);
      settecnicos(tec);
    }
  }, [date, tec]);

  const headers = [
    {
      name: "Técnicos",
      field: "name",
    },
    {
      name: "Total de ordenes cumplidas",
      field: "Cumplidas",
    },
  ];

  return (
    <>
      {tecnicos !== null && tecnicos && tecnicos.tec.exp.length >= 1 ? (
        <>
          <div className="d-flex justify-content-between align-items-center w-100">
            <div className="d-flex align-items-center w-100 justify-content-between mb-2">
              <div className="d-flex w-100 mb-2">
                <p className="traf mb-0">Técnicos</p>

                {date && (
                  <p className="ms-1 mb-0 traf">
                    ({date[0].toLocaleDateString("es-VE")} -{" "}
                    {date[1].toLocaleDateString("es-VE")})
                  </p>
                )}

                <div className="ms-auto">
                  <ExportMSheets
                    apiData={tecnicos.tec.exp}
                    fileName={"Técnicos"}
                    field={"name"}
                    nNumber
                    allData={records}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="h-100">
            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-graf-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-graf"
                  type="button"
                  role="tab"
                  aria-controls="pills-graf"
                  aria-selected="false"
                >
                  Gráfica
                </button>
              </li>

              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-table-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-table"
                  type="button"
                  role="tab"
                  aria-controls="pills-table"
                  aria-selected="false"
                >
                  Tabla
                </button>
              </li>
            </ul>

            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-graf"
                role="tabpanel"
                aria-labelledby="pills-graf-tab"
              >
                <>
                  <LineChartt data={tecnicos.tec.exp} />
                </>
              </div>

              <div
                className="tab-pane fade"
                id="pills-table"
                role="tabpanel"
                aria-labelledby="pills-table-tab"
              >
                <table className="table table-mdt table-striped">
                  <TableHeader headers={headers} />
                  <tbody>
                    {tecnicos &&
                      tecnicos.tec.exp &&
                      tecnicos.tec.exp.map((comment, index) => (
                        <tr key={index}>
                          <td>{comment.name}</td>
                          <td className="text-center">
                            <span className="badge bg-primary rounded-pill">
                              {comment.refs.length}
                            </span>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <p className="traf mb-0">Técnicos</p>
          <div className="d-flex my-auto mx-auto mt-3">
            <div className="h-100 my-auto">
              <Error
                title={"No se han encontrado registros."}
                icon={"fa-solid fa-circle-xmark icon-error"}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Tecnicos;
